<template>
  <div class="money-ul">
    <!-- <h2>提现记录</h2> -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <ul>
        <li class="money-li" v-for="(item, index) in moneyList" :key="index">
          <div class="left">
            <div class="info">
              <b v-if="item.pdc_payment_state == 0">
                申请成功
              </b>
              <b v-if="item.pdc_payment_state == 1">
                提现成功
              </b>
              <b v-if="item.pdc_payment_state == 2">
                提现失败
              </b>
              <span> ￥{{ item.pdc_amount }} </span>
            </div>
            <span class="time">{{ item.pdc_addtime | time }}</span>
          </div>
          <div class="right">
            <svg
              v-if="item.pdc_payment_state == 0"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="20"
                cy="20"
                r="18"
                stroke="#118FE7"
                stroke-width="3"
              />
              <path
                d="M18.9948 13.0052L18.9952 21.4928L30.4928 21.4928"
                stroke="#118FE7"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <svg
              v-if="item.pdc_payment_state == 1"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="20"
                cy="20"
                r="18"
                stroke="#21CA71"
                stroke-width="3"
              />
              <path
                d="M11 21.3571L16.6604 26L28.5 14"
                stroke="#21CA71"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <svg
              v-if="item.pdc_payment_state == 2"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="20"
                cy="20"
                r="18"
                stroke="#FF4848"
                stroke-width="3"
              />
              <path
                d="M14.5693 26.2832L26.409 14.2832"
                stroke="#FF4848"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.8828 14.2832L25.8828 26.1228"
                stroke="#FF4848"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </li>
      </ul>
    </van-list>
  </div>
</template>

<script>
import { post } from "@get/http";
import { List } from "vant";
export default {
  data() {
    return {
      loading: false,
      finished: false,
      page: 1,
      moneyList: [],
    };
  },
  components: {
    vanList: List,
  },
  created() {
    this.onLoad();
  },
  methods: {
    async onLoad() {
      let { data } = await post("/v2.Memberfund/pdcashlist", {
        data: {
          page: this.page,
          per_page: 20,
          // time_type: 0,
        },
      });
      this.moneyList.push(...data);

      this.loading = false;
      this.page++;
      // 加载状态结束
      if (data && data.length != 20) {
        this.finished = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.money-ul {
  width: rem(690);
  margin: 0 auto;
  padding-top: rem(16);
  h2 {
    font-weight: bold;
    font-size: rem(28);
    color: #222222;
    padding-left: rem(24);
    margin: rem(24) 0;
  }
  .money-li {
    box-sizing: border-box;
    padding: rem(24);
    display: flex;
    background: #fff;
    border-radius: rem(16);
    margin-bottom: rem(16);
    align-items: center;

    .left {
      flex: 1;
      .info {
        flex: 1;
        > b {
          font-size: rem(28);
          color: #222222;
        }
        > span {
          color: #ff4848;
          font-weight: bold;
          font-size: rem(28);
          margin-left: rem(6);
          display: inline-block;
        }
      }
      .time {
        color: #666666;
        font-size: rem(24);
        margin-top: rem(8);
      }
    }
    .right {
      svg {
        width: rem(36);
        height: rem(36);
      }
    }
  }
}
</style>
